import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

const styleMap = {
  employer: {
    variant: 'h5',
    fontWeight: 'fontWeightBold',
    hidden: ''
  },
  title: {
    variant: 'h5',
    fontWeight: 'fontWeightBold',
    hidden: ''
  },
  dates: {
    variant: 'h6',
    hidden: ''
  },
  description: {
    paragraph: true,
    hidden: ['xs', 's', 'm']
  }
}
const theme = {
  spacing: 50,
}
const renderGridItem = (key, text) => {
  return (
    // hide job description on medium and smaller
    <Hidden only={styleMap[key].hidden}>
      <Grid item xs={12} md={6}>
        <Typography align='left' paragraph={styleMap[key].paragraph} variant={styleMap[key].variant}>
          <Box fontWeight={styleMap[key].fontWeight} lineHeight={1.5}>
            {text}
          </Box>
        </Typography>
      </Grid>
    </Hidden>
  )
}

export const Experience = () => {
  const jobs = [
    {
      employer: 'TD Bank',
      title: 'Cloud Software Engineer',
      startDate: 'July 2019',
      endDate: 'Present',
      description: 'I am currently working as a Cloud Software Engineer with TD Bank. My team primarily works with SaltStack and Terraform, and I have become a subject matter expert for both technologies. My day to day activities usually include include writing Salt states, Terraform modules, Jenkins pipelines, and Inspec tests.'
    },
    {
      employer: 'Peraso Technologies',
      title: 'DevOps Engineer (co-op)',
      startDate: 'September 2018',
      endDate: 'December 2018',
      description: 'For my final co-op term, I worked at Peraso Technologies as a DevOps engineer. Some of the projects I worked on included developing REST API with Flask to assist with creating, updating and reading node information in our deployment process. I also worked with my team to harness the power of Packer and VirtualBox, and create a script that installs a local development environment.'
    },
    {
      employer: 'Manulife Financial',
      title: 'Data Engineer (co-op)',
      startDate: 'January 2018',
      endDate: 'April 2018',
      description: 'This was my second co-op at Manulife (this time working with big data), and it was incredible - I learned so much about big data, programming, and technology. The main tools and technologies I used were Python, Hadoop (HDFS/Hive), SQL, Linux, Git, and Django. I worked on a number of projects, but the most exciting ones were automating an ETL process and using Natural Language Processing to perform sentiment analysis on data from our HR team.'
    },
    {
      employer: 'Manulife Financial',
      title: 'Business Analyst (co-op)',
      startDate: 'May 2017',
      endDate: 'September 2017',
      description: 'This was my first time working in Toronto, and I thoroughly enjoyed it. As a Business Analyst I helped my team identify areas in a workflow where certain work items were being rejected. This allowed us to focus our time, and analyze these specific items to determine the root cause.'
    }
  ]

  const jobs_jsx = jobs.map((job, idx) => (
    <React.Fragment key={idx}>
      {renderGridItem('employer', job.employer)}
      {renderGridItem('title', job.title)}
      {renderGridItem('dates', `${job.startDate} — ${job.endDate}`)}
      {renderGridItem('description', job.description)}
    </React.Fragment>
  ));

  return (
    <div>
      <Typography align='center' variant='h2' paragraph={true}>Experience</Typography>
      <Grid container style={{padding: '0 20%'}}>
        {jobs_jsx}
      </Grid>
    </div>
  );
}
