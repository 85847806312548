import React, { Component, Fragment } from 'react';
import './App.css';
import Navbar from './components/Navbar'
import { Home, About, Experience, NotFound } from './components/Pages'
import { Route, Switch, Link } from 'react-router-dom';

function Pages(props) {
  let pages = props.pages.map(page => (
    <Route exact path={page.path} component={page.component} />
  ))

  return (
    <Switch>
      {pages}
      <Route component={NotFound} />
    </Switch>
  )
}

function App() {

  const pages = [
    {
      name: "Chris Bombino",
      path: "/",
      component: Home
    },
    {
      name: "About",
      path: "/about",
      component: About
    },
    {
      name: "Experience",
      path: "/experience",
      component: Experience
    }
  ]

// thanks to this article for showing how to acheive a react-router navbar with Material UI
// https://stackoverflow.com/questions/41638688/material-uis-tabs-integration-with-react-router-4/41654699
  return (
    <div className="App">
      <Route
        path="/"
        render={ location => (
          <Fragment>
            <Navbar tabs={pages} location={location}/>
            <Pages pages={pages} />
          </Fragment>
        )}
      />
    </div>
  );
}

export default App;
