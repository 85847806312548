import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import headshot from './headshot.jpeg';

export const About = () => {
  return (
    <Grid container style={{padding: '3% 12%'}}>
      <Grid item xs={12} md={6}>
        <img src={headshot} style={{maxWidth: '80%'}}></img>
      </Grid>
      <Grid item xs={12} md={6} align='left' style={{paddingLeft: '3%'}}>
        <Typography paragraph={true} variant='h4'>About Me</Typography>
        <Typography paragraph={true}>
          Thanks for visiting my website! I'm a software engineer currently employed with TD Bank on an Infrastructure as Code/Cloud Software team. I have experience working with a variety of tools including SaltStack, Terraform and Docker, as well as various public and private cloud resources. I enjoy solving complex technical problems and am especially interested in helping organizations embrace Infrastructure as Code and DevOps (I also enjoy backend engineering as well!).
        </Typography>
        <Typography paragraph={true}>
          In 2019 I graduated from the University of Waterloo with a degree in <Link href='https://uwaterloo.ca/management-sciences/about-management-sciences/about-management-engineering' target="_blank" rel="noopener">Management Engineering</Link>. Relevant coursework includes Data Structures and Algorithms, Database Systems, Human Computer Interaction, and Princinples of Software Engineering.
        </Typography>
        <Typography>
          Some of my hobbies outside of work include cooking, reading, snowboarding, or learning something new from one of my various side projects (like this one!). Checkout my <Link href='https://github.com/chrisbombino' target="_blank" rel="noopener">GitHub</Link> if you're interested to see what I've worked on.
        </Typography>
      </Grid>
    </Grid>
  );
}
