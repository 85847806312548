import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Navbar = (props) => {
  let tabs = props.tabs.map(tab => (
    <Tab label={tab.name} value={tab.path} component={Link} to={tab.path} />
  ))
  return (
    <Tabs value={props.location}>
      {tabs}
    </Tabs>
  )
}

export default Navbar;
