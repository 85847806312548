import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import background from '../../paris.jpeg';

const imgStyles = {
  height: '100vh',
  backgroundSize: 'cover',
  backgroundImage: `url(${background})`
}

const styles = {
  color: 'white',
  paddingLeft: '8%',
  textAlign: 'left',
}

export const Home = () => {
  return (
    <div>
      <div style={imgStyles}>
        <div style={styles}>
          <Typography variant='h2' style={{paddingTop: '13%'}}>
            CHRIS BOMBINO
          </Typography>
          <Typography variant='h4' style={{width: '50%'}}>
            An engineering student who loves people, coding, and cooking
          </Typography>
        </div>
      </div>
    </div>
  )
}
